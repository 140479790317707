#header {
    font-size: 18px;
    padding: 25px 0 22px 3px;
    .leftSideTop {
        img {
            width: 120px;
            @media only screen and (min-width: 768px) {
                margin-left: -8px;
                margin-top: -10px;
                float: left;
            }
        }
        p {
            margin-bottom: 0;
            color: black;
            font-family: 'Alegreya SC', serif;
            line-height: 29px;
            @media only screen and (min-width: 768px) {
                border-top: 1px solid #D8DFEA;
                margin-left: 147px;
                margin-top: -31px;
                width: 272px;
            }
            &.bigTop {
                width: initial;
                font-size: 47px;
                line-height: 44px;
                border-top: 0px solid #D8DFEA;
                margin-left: 126px;
                margin-top: -5px;
                color: #003153;
                @media only screen and (max-width: 767px) {
                    margin: 0 auto;
                }
                @media only screen and (min-width: 768px) {
                    padding: 5px 0px 32px 17px;
                    border-left: 2px solid #003153;
                }
            }
        }
    }
    span {
        color: $default-color;
        font-size: 20px;
        line-height: 10px;
        span {
            font-size: 23px;
        }
    }
    .to_right {
        text-align: right;
        font-size: 14px;
        color: #66635B;
        span {
            font-size: 18px;
            margin-left: 10px;
        }
        .languageSwith {
            float: right;
            color: $default-color;
            text-transform: uppercase;
            @media only screen and (max-width: 767px) {
                float: none;
                text-align: center;
            }
            a {
                display: inline-block;
                padding-left: 10px;
                padding-right: 8px;
                font-weight: 700;
                font-size: 16px;
                border-left: 2px solid $default-color;
                &:first-of-type {
                    border-left: 0px solid white;
                    padding-left: 22px;
                    @media only screen and (max-width: 767px) {
                        padding-left: 0;
                    }
                }
                &:last-of-type {
                    padding-right: 0;
                }
                &.currentLang {
                    pointer-events: none;
                    color: #525252;
                }
            }
        }
        .phoneNumberTop {
            float: right;
            color: $default-color;
            border-right: 1px solid $default-color;
            padding: 12px 15px 4px 0;
            margin-top: -10px;
            margin-bottom: 0;
            a {
                span {
                    font-size: 22px;
                    margin-left: 1px;
                }
            }
        }
    }
    .search {
        margin-top: 7px;
    }
    #search_word-error {
        position: absolute;
        top: 25px;
        right: 134px;
        color: red;
    }
    #search_word,
    #search_word_xs {
        margin-top: 2px;
        width: 290px;
        height: 40px;
        border: 1px solid #8D8D8D;
    }
    #start_search {
        border: 0px solid white;
        height: 40px;
        width: 40px;
        margin-top: -2px;
        margin-left: 3px;
        padding: 0;
        i {
            margin-top: -4px;
            margin-left: 1px;
            font-size: 36px;
        }
    }
    a:hover, a:focus {
        text-decoration: none;
        background-color: white;
    }
}


/** AKTUALNOŚCI START **/
.widget3 {
    font-family: 'Lato';
    .heading {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 7px;
        font-size: 20px;
        color: $default-color;
        line-height: 38px;
        border-bottom: 3px solid $default-color;
        i {
            font-size: 45px;
            display: block;
            float: left;
            margin: 0px 10px 0px -11px;
        }
    }
    .singleNewsStartPage {
        display: block;
        margin-top: 25px;
        margin-bottom: 36px;
        color: #403D31;
        text-decoration: none !important;
        &:hover {
            color: #403D31;
        }
        h2 {
            font-size: 25px;
            font-weight: 700;
            margin-top: 18px;
            margin-left: 3px;
        }
        p {
            max-width: 90%;
        }
    }
}
/** AKTUALNOŚCI   END **/
#contact {
    margin-top: 50px;
    border-top: 3px solid $default-color;
    color: #111111;
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
    a {
        color: #111111;
    }
    h3 {
        font-size: 30px;
        text-transform: uppercase;
        margin-top: 50px;
        font-weight: 700;
        @media only screen and (max-width: 1199px) {
            margin-top: 25px;
            font-size: 25px;
        }
    }
    p {
        padding-bottom: 0;
        margin-bottom: 2px;
    }
    .tekst {
        font-size: 18px;
        @media only screen and (max-width: 1199px) {
            font-size: 15px;
        }
    }
    img {
        margin: 15px 0px;
        @media only screen and (max-width: 767px) {
            margin: 15px auto;
        }
    }
} 
/** FOOTER START **/
#optimal {
    padding-top: 18px;
    color: #7D7D7D;
    border-top: 1px solid #D4D4D4;
    padding-bottom: 20px;
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
    i {
        display: inline-block;
        vertical-align: text-bottom;
    }
    .realization {
        text-align: right;
        @media only screen and (max-width: 767px) {
            text-align: center;
            padding-top: 10px;
        }
        img {
            vertical-align: -1px;
        }
    }
    * {
        color: #AAAAAA;
        font-size: 12px;
    }
    .linktToPP {
        display: block;
        margin-top: 7px;
        text-decoration: underline;
    }
}
/** FOOTER   END **/
@media only screen and (max-width: 767px) {
    .col-xxs-12  {
        width: 100%;
    }
}
.--right {
    float: right;
}
.--left {
    float: left;
}