#menu .dropdown-submenu {
    position: relative;
}
nav {
    &.navbar {
        border: 0px solid white !important;
    }
    .navbar-nav > li > a {
        text-transform: uppercase;
        padding: 23px 27px 19px 27px;
        font-size: 15px;
    }
    .navbar-brand {
        i {
            font-size: 24px;
            margin-top: 4px;
        }
    }
    font-size: 15px;
    .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -18px;
        left: 50%;
        margin-left: -10px;
        @media only screen and (max-width: 767px) {
            opacity: 0;
        }
    }
    .dropdown-menu {
        top: 90%;
        font-size: 16px;
        background-color: white;
        border-radius: 6px !important;
        padding: 23px 0px 12px 0;
        li {
            &:first-of-type {
                display: none;
            }
            a {
                color: #5F5F5F;
                padding: 7px 20px 8px 20px;
            }
            a:hover {
                color: $default-color;
                background-color: white;
            }
            &.divider {
                margin: 5px 12px;
            }
        }
    }
}


.dropdown-submenu {
    position: relative;
    .dropdown-submenu .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-right: 10px solid white;
        position: absolute;
        top: 10px;
        left: -19px;
    }
    .dropdown-submenu {
        .caret {
            display: none;
        }
    }
    .dropdown-submenu >.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
    }
    .dropdown-submenu > a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }
    .dropdown-submenu .hover + .dropdown-menu {
        display: block;
    }
}
.navbar-right .dropdown-menu {
    right: -69px;
    left: auto;
}

.navbar-default {
    .navbar-nav {
        a:hover {
            color: #D8DFEA !important;
        }
        > .open {
            > a {
                font-weight: 700;
                &:hover {
                    color: black !important;
                }
            }
        }
        .dropdown-menu {
            a:hover {
                color: $default-color !important;
            }
            .activeMenu {
                background: #fff;
            }
        }
        .activeMenu {
            color: $default-color !important;
            background: #D8DFEA;
            font-weight: 700;
            color: black !important;
            > a {
                color: $default-color !important;
                font-weight: 700;
                &:hover {
                    color: black !important;
                }
            }
        }
    }
}
