@charset "UTF-8";
/*-- CLASS FOR INHERIT START --*/
.fontAwesome, .pageContainer .fa {
  font-size: 36px;
  display: block;
  float: left;
  margin: 0px 10px 0px -3px; }

.tinymceContent, .pageContent, .newsContent {
  width: 100%;
  overflow: hidden; }
  .tinymceContent img, .pageContent img, .newsContent img {
    padding: 0 10px;
    height: auto !important;
    max-width: 100% !important; }

/*-- CLASS FOR INHERIT   END --*/
* {
  font-family: 'Oxygen', sans-serif; }

.bold {
  font-weight: 700; }

.titleTopRight p {
  text-align: right;
  font-size: 18px; }

#menu .dropdown-submenu {
  position: relative; }

nav {
  font-size: 16px; }
  nav .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 42%; }
  nav .dropdown-menu {
    top: 90%;
    font-size: 16px;
    background-color: white;
    border-radius: 6px !important; }
    nav .dropdown-menu li:first-of-type {
      display: none; }
    nav .dropdown-menu a {
      color: #555; }
    nav .dropdown-menu a:hover {
      color: #003153;
      background-color: white; }
    nav .dropdown-menu .divider {
      margin: 5px 12px; }

.bannersContainer {
  background-color: #D8DFEA;
  margin-top: -17px;
  margin-bottom: 30px; }
  .bannersContainer #sliderTop .carousel-indicators {
    bottom: 3px; }
    .bannersContainer #sliderTop .carousel-indicators li, .bannersContainer #sliderTop .carousel-indicators .active {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: 4px solid white;
      background-color: #D8DFEA; }
    .bannersContainer #sliderTop .carousel-indicators .active {
      background-color: #003153; }

.pageContainer {
  min-height: 60vh;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .pageContainer {
      padding: 0 30px; } }
  .pageContainer h1 {
    margin-top: 3px;
    background-color: #003153;
    color: white;
    font-size: 20px;
    padding: 18px;
    font-weight: 700;
    margin-bottom: 35px; }
    .pageContainer h1 .handshake {
      vertical-align: 9px;
      margin-bottom: -20px;
      margin-top: -10px;
      margin-right: 3px; }
  .pageContainer .pageContent {
    font-size: 16px; }
  .pageContainer .panel-heading {
    font-size: 20px;
    height: 58px;
    line-height: 38px; }
  .pageContainer .panel-primary {
    border: 0px solid white; }
    .pageContainer .panel-primary .panel-body {
      background-color: #eaeaea; }
  .pageContainer .widget3 a {
    color: #555; }
  .pageContainer .widget3 img {
    margin-top: 10px; }
  .pageContainer .widget3 .heading {
    color: #003153;
    font-weight: bold;
    font-size: 20px;
    height: 58px;
    line-height: 38px;
    padding: 10px 15px;
    border-bottom: 3px solid #003153;
    margin-bottom: 5px; }
  .pageContainer .widget3 .top_hr h4 {
    border-top: 1px solid #003153;
    padding-top: 10px; }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .pageContainer .widget3 .item1 h4 {
      border-top: 0px solid #003153; } }
  .pageContainer .widget3 .readMore {
    margin-top: 20px;
    color: white; }
  .pageContainer .readMore {
    float: right; }
  .pageContainer .listSpace {
    height: 20px;
    clear: both; }

.imageGallery {
  color: #fff;
  font-size: 0.0001em; }
  .imageGallery img {
    width: 24%;
    margin: 10px 0.5%;
    border-radius: 5px; }
    @media only screen and (max-width: 768px) {
      .imageGallery img {
        width: 32.3%; } }
    @media only screen and (max-width: 480px) {
      .imageGallery img {
        width: 49%; } }

.fileList {
  text-align: center; }
  .fileList .file {
    min-height: 170px; }
    .fileList .file img {
      margin: 0 auto; }

.clear {
  clear: both; }

/*-- NEWS LIST START --*/
.singleNews {
  position: relative;
  display: block;
  padding: 15px; }
  .singleNews .img-responsive {
    margin: 0 auto; }
  .singleNews .description {
    color: #555;
    font-size: 16px;
    padding-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .singleNews h2 {
      margin-top: 0; } }
  .singleNews .buttonNews {
    position: absolute;
    bottom: 10px;
    right: 7px;
    text-transform: uppercase; }
    .singleNews .buttonNews * {
      font-size: 18px; }
    .singleNews .buttonNews .fa {
      float: none;
      font-size: 25px;
      display: inline-block;
      margin-left: 6px; }
  .singleNews .timeNews {
    margin-top: -3px;
    margin-bottom: 10px; }
    .singleNews .timeNews * {
      color: black;
      font-size: 15px;
      display: inline-block; }
    .singleNews .timeNews .fa {
      color: #003153;
      font-size: 18px;
      float: none;
      margin: 0; }

.singleNews:hover {
  background-color: #003153; }
  .singleNews:hover * {
    color: white !important; }

/*-- NEWS LIST   END --*/
/*-- NEWS SINGLE START --*/
/*-- NEWS SINGLE   END --*/
#menu .dropdown-submenu {
  position: relative; }

nav {
  font-size: 15px; }
  nav.navbar {
    border: 0px solid white !important; }
  nav .navbar-nav > li > a {
    text-transform: uppercase;
    padding: 23px 27px 19px 27px;
    font-size: 15px; }
  nav .navbar-brand i {
    font-size: 24px;
    margin-top: 4px; }
  nav .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 50%;
    margin-left: -10px; }
    @media only screen and (max-width: 767px) {
      nav .dropdown-menu::before {
        opacity: 0; } }
  nav .dropdown-menu {
    top: 90%;
    font-size: 16px;
    background-color: white;
    border-radius: 6px !important;
    padding: 23px 0px 12px 0; }
    nav .dropdown-menu li:first-of-type {
      display: none; }
    nav .dropdown-menu li a {
      color: #5F5F5F;
      padding: 7px 20px 8px 20px; }
    nav .dropdown-menu li a:hover {
      color: #003153;
      background-color: white; }
    nav .dropdown-menu li.divider {
      margin: 5px 12px; }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu .dropdown-submenu .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-right: 10px solid white;
    position: absolute;
    top: 10px;
    left: -19px; }
  .dropdown-submenu .dropdown-submenu .caret {
    display: none; }
  .dropdown-submenu .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px; }
  .dropdown-submenu .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px; }
  .dropdown-submenu .dropdown-submenu .hover + .dropdown-menu {
    display: block; }

.navbar-right .dropdown-menu {
  right: -69px;
  left: auto; }

.navbar-default .navbar-nav a:hover {
  color: #D8DFEA !important; }

.navbar-default .navbar-nav > .open > a {
  font-weight: 700; }
  .navbar-default .navbar-nav > .open > a:hover {
    color: black !important; }

.navbar-default .navbar-nav .dropdown-menu a:hover {
  color: #003153 !important; }

.navbar-default .navbar-nav .dropdown-menu .activeMenu {
  background: #fff; }

.navbar-default .navbar-nav .activeMenu {
  color: #003153 !important;
  background: #D8DFEA;
  font-weight: 700;
  color: black !important; }
  .navbar-default .navbar-nav .activeMenu > a {
    color: #003153 !important;
    font-weight: 700; }
    .navbar-default .navbar-nav .activeMenu > a:hover {
      color: black !important; }

#header {
  font-size: 18px;
  padding: 25px 0 22px 3px; }
  #header .leftSideTop img {
    width: 120px; }
    @media only screen and (min-width: 768px) {
      #header .leftSideTop img {
        margin-left: -8px;
        margin-top: -10px;
        float: left; } }
  #header .leftSideTop p {
    margin-bottom: 0;
    color: black;
    font-family: 'Alegreya SC', serif;
    line-height: 29px; }
    @media only screen and (min-width: 768px) {
      #header .leftSideTop p {
        border-top: 1px solid #D8DFEA;
        margin-left: 147px;
        margin-top: -31px;
        width: 272px; } }
    #header .leftSideTop p.bigTop {
      width: initial;
      font-size: 47px;
      line-height: 44px;
      border-top: 0px solid #D8DFEA;
      margin-left: 126px;
      margin-top: -5px;
      color: #003153; }
      @media only screen and (max-width: 767px) {
        #header .leftSideTop p.bigTop {
          margin: 0 auto; } }
      @media only screen and (min-width: 768px) {
        #header .leftSideTop p.bigTop {
          padding: 5px 0px 32px 17px;
          border-left: 2px solid #003153; } }
  #header span {
    color: #003153;
    font-size: 20px;
    line-height: 10px; }
    #header span span {
      font-size: 23px; }
  #header .to_right {
    text-align: right;
    font-size: 14px;
    color: #66635B; }
    #header .to_right span {
      font-size: 18px;
      margin-left: 10px; }
    #header .to_right .languageSwith {
      float: right;
      color: #003153;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        #header .to_right .languageSwith {
          float: none;
          text-align: center; } }
      #header .to_right .languageSwith a {
        display: inline-block;
        padding-left: 10px;
        padding-right: 8px;
        font-weight: 700;
        font-size: 16px;
        border-left: 2px solid #003153; }
        #header .to_right .languageSwith a:first-of-type {
          border-left: 0px solid white;
          padding-left: 22px; }
          @media only screen and (max-width: 767px) {
            #header .to_right .languageSwith a:first-of-type {
              padding-left: 0; } }
        #header .to_right .languageSwith a:last-of-type {
          padding-right: 0; }
        #header .to_right .languageSwith a.currentLang {
          pointer-events: none;
          color: #525252; }
    #header .to_right .phoneNumberTop {
      float: right;
      color: #003153;
      border-right: 1px solid #003153;
      padding: 12px 15px 4px 0;
      margin-top: -10px;
      margin-bottom: 0; }
      #header .to_right .phoneNumberTop a span {
        font-size: 22px;
        margin-left: 1px; }
  #header .search {
    margin-top: 7px; }
  #header #search_word-error {
    position: absolute;
    top: 25px;
    right: 134px;
    color: red; }
  #header #search_word,
  #header #search_word_xs {
    margin-top: 2px;
    width: 290px;
    height: 40px;
    border: 1px solid #8D8D8D; }
  #header #start_search {
    border: 0px solid white;
    height: 40px;
    width: 40px;
    margin-top: -2px;
    margin-left: 3px;
    padding: 0; }
    #header #start_search i {
      margin-top: -4px;
      margin-left: 1px;
      font-size: 36px; }
  #header a:hover, #header a:focus {
    text-decoration: none;
    background-color: white; }

/** AKTUALNOŚCI START **/
.widget3 {
  font-family: 'Lato'; }
  .widget3 .heading {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 7px;
    font-size: 20px;
    color: #003153;
    line-height: 38px;
    border-bottom: 3px solid #003153; }
    .widget3 .heading i {
      font-size: 45px;
      display: block;
      float: left;
      margin: 0px 10px 0px -11px; }
  .widget3 .singleNewsStartPage {
    display: block;
    margin-top: 25px;
    margin-bottom: 36px;
    color: #403D31;
    text-decoration: none !important; }
    .widget3 .singleNewsStartPage:hover {
      color: #403D31; }
    .widget3 .singleNewsStartPage h2 {
      font-size: 25px;
      font-weight: 700;
      margin-top: 18px;
      margin-left: 3px; }
    .widget3 .singleNewsStartPage p {
      max-width: 90%; }

/** AKTUALNOŚCI   END **/
#contact {
  margin-top: 50px;
  border-top: 3px solid #003153;
  color: #111111; }
  @media only screen and (max-width: 767px) {
    #contact {
      text-align: center; } }
  #contact a {
    color: #111111; }
  #contact h3 {
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 50px;
    font-weight: 700; }
    @media only screen and (max-width: 1199px) {
      #contact h3 {
        margin-top: 25px;
        font-size: 25px; } }
  #contact p {
    padding-bottom: 0;
    margin-bottom: 2px; }
  #contact .tekst {
    font-size: 18px; }
    @media only screen and (max-width: 1199px) {
      #contact .tekst {
        font-size: 15px; } }
  #contact img {
    margin: 15px 0px; }
    @media only screen and (max-width: 767px) {
      #contact img {
        margin: 15px auto; } }

/** FOOTER START **/
#optimal {
  padding-top: 18px;
  color: #7D7D7D;
  border-top: 1px solid #D4D4D4;
  padding-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    #optimal {
      text-align: center; } }
  #optimal i {
    display: inline-block;
    vertical-align: text-bottom; }
  #optimal .realization {
    text-align: right; }
    @media only screen and (max-width: 767px) {
      #optimal .realization {
        text-align: center;
        padding-top: 10px; } }
    #optimal .realization img {
      vertical-align: -1px; }
  #optimal * {
    color: #AAAAAA;
    font-size: 12px; }
  #optimal .linktToPP {
    display: block;
    margin-top: 7px;
    text-decoration: underline; }

/** FOOTER   END **/
@media only screen and (max-width: 767px) {
  .col-xxs-12 {
    width: 100%; } }

.--right {
  float: right; }

.--left {
  float: left; }

@media only screen and (max-width: 768px) {
  .navbar-default {
    background-color: #ffffff;
    border-color: #FFFFFF; }
  .navbar-toggle .icon-bar {
    background-color: white;
    height: 3px; }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: 0px solid #ffffff;
    background-color: #003153;
    margin-top: 10px; }
  .navbar-default .navbar-toggle, .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    padding: 8px;
    width: 44px;
    height: 40px;
    display: block;
    margin-right: 30%; }
  #header {
    text-align: center; }
    #header span {
      font-size: 21px !important; }
  #header span span {
    font-size: 24px !important; }
  .call_button {
    width: 44px;
    height: 40px;
    padding: 0;
    font-size: 31px;
    position: absolute;
    z-index: 10001;
    left: 0;
    display: inline;
    border: 1px solid transparent; }
  .btn-default:hover {
    border-color: #217db9; }
  #header #start_search {
    width: 44px; }
  .relative {
    position: relative;
    width: 152px;
    margin: 0 auto;
    margin-bottom: -50px; }
  .search_button_xs {
    position: relative;
    z-index: 10001;
    width: 44px;
    height: 40px;
    padding: 0;
    display: inline-block;
    margin-left: 54px;
    border: 1px solid transparent;
    font-size: 31px;
    font-size: 29px;
    line-height: 12px; }
  .navbar-toggle {
    position: relative;
    z-index: 10001;
    padding: 8px;
    width: 44px;
    height: 40px;
    background-color: #003153;
    margin: 0 !important;
    display: inline-block; }
  .panel-default .panel-heading {
    min-height: 58px;
    height: auto; }
  #myModal {
    z-index: 100000; }
  #search_word_xs {
    width: 100%;
    height: 40px;
    padding: 5px; }
  .title {
    font-size: 38px; }
  .panel-default .panel-heading {
    font-size: 18px; }
  .news .heading {
    font-size: 18px; } }
