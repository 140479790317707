/*-- NEWS LIST START --*/
.singleNews {
    position: relative;
    display: block;
    padding: 15px;
    .img-responsive {
        margin: 0 auto;
    }
    .description {
        color: $light-text;
        font-size: 16px;
        padding-bottom: 20px;
    }
    h2 {
        @media only screen and (min-width: $screen-sm) {
            margin-top: 0;
        }
    }
    .buttonNews {
        position: absolute;
        bottom: 10px;
        right: 7px;
        text-transform: uppercase;
        * {
            font-size: 18px;
        }
        .fa {
            float: none;
            font-size: 25px;
            display: inline-block;
            margin-left: 6px;
        }
    }
    .timeNews {
        * {
            color: black;
            font-size: 15px;
            display: inline-block;
        }
        .fa {
            color: $default-color;
            font-size: 18px;
            float: none;
            margin: 0;
        }
        margin-top: -3px;
        margin-bottom: 10px;
    }
}
.singleNews:hover {
    background-color: $button-color;
    * {
        color: white !important;
    }
}
/*-- NEWS LIST   END --*/


/*-- NEWS SINGLE START --*/
.newsContent {
    @extend .tinymceContent;
}
/*-- NEWS SINGLE   END --*/